var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{staticClass:"clientdata",attrs:{"label-col":{},"wrapper-col":{}}},[_c('a-row',{attrs:{"gutter":48}},_vm._l((_vm.$v.fields.$each.$iter),function(v){return _c('a-col',{key:v.$model.id,staticClass:"form-item",attrs:{"span":12}},[_c('a-form-item',{attrs:{"has-feedback":v.value.minLength,"validate-status":v.value.$error || v.name.$error ? 'error' : '',"label":v.$model.name,"help":(v.value.$error && 'Необходимо заполнить поле') ||
            (!v.name.customCheckFields && 'Не правильный ИНН')}},[(v.$model.id === 10)?_c('a-select',{staticClass:"selects",attrs:{"placeholder":v.$model.placeholder,"disabled":!_vm.isDealer},on:{"change":_vm.handleCityChange},model:{value:(v.value.$model),callback:function ($$v) {_vm.$set(v.value, "$model", $$v)},expression:"v.value.$model"}},_vm._l((_vm.cities),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1):_vm._e(),(![10, 11].includes(v.$model.id))?_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:(v.$model.mask),expression:"v.$model.mask"}],attrs:{"placeholder":v.$model.placeholder,"value":_vm.checkFields(v.$model.createclientname),"disabled":v.$model.disabled ||
              (![8, 7, 5, 12, 13].includes(v.$model.id) && _vm.isBuyerAdmin)},on:{"input":function (e) { return _vm.setField({
                  name: v.$model.createclientname,
                  value: e.target.value,
                }); }},model:{value:(v.value.$model),callback:function ($$v) {_vm.$set(v.value, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"v.value.$model"}}):_vm._e(),(v.$model.id === 11)?_c('div',[_c('a-popover',{attrs:{"title":"Адрес доставки должен содержать:","trigger":"hover"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',[_vm._v("Индекс, регион, населенный пункт, улицу, дом, строение")]),_c('p',[_vm._v("Данная информацмя передается в транспортную компанию")])]},proxy:true}],null,true)},_vm._l((_vm.createForm.legalInfoPhysicalAddress),function(item,index){return _c('a-input',{key:index,attrs:{"placeholder":v.$model.placeholder,"value":item,"disabled":!(_vm.isDealer || _vm.isBuyerAdmin)},on:{"input":function (e) { return _vm.setPhysicalAddress({ index: index, value: e.target.value }); }}})}),1),(_vm.isManufacturerAdmin || _vm.isBuyerAdmin)?_c('a-button',{staticClass:"btnClient",on:{"click":_vm.addPhysicalAddress}},[_vm._v(" Добавить ")]):_vm._e()],1):_vm._e()],1)],1)}),1)],1),(
      this.$route.name !== 'CreateNewClient' && (_vm.isDealer || _vm.isBuyerAdmin)
    )?_c('div',{staticClass:"buttonsholder"},[(_vm.isManufacturerAdmin || _vm.isBuyerAdmin)?_c('ButtonsHolderUpdateClient'):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }