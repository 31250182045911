<template>
  <div>
    <a-form :label-col="{}" :wrapper-col="{}" class="clientdata">
      <a-row :gutter="48">
        <a-col
          v-for="v in $v.fields.$each.$iter"
          :key="v.$model.id"
          class="form-item"
          :span="12"
        >
          <a-form-item
            :has-feedback="v.value.minLength"
            :validate-status="v.value.$error || v.name.$error ? 'error' : ''"
            :label="v.$model.name"
            :help="
              (v.value.$error && 'Необходимо заполнить поле') ||
              (!v.name.customCheckFields && 'Не правильный ИНН')
            "
          >
            <!-- CityId -->
            <a-select
              v-if="v.$model.id === 10"
              :placeholder="v.$model.placeholder"
              class="selects"
              :disabled="!isDealer"
              v-model="v.value.$model"
              @change="handleCityChange"
            >
              <a-select-option
                v-for="item in cities"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
            <!-- Others fields -->
            <a-input
              v-if="![10, 11].includes(v.$model.id)"
              v-model.trim="v.value.$model"
              @input="
                e =>
                  setField({
                    name: v.$model.createclientname,
                    value: e.target.value,
                  })
              "
              :placeholder="v.$model.placeholder"
              :value="checkFields(v.$model.createclientname)"
              v-mask="v.$model.mask"
              :disabled="
                v.$model.disabled ||
                (![8, 7, 5, 12, 13].includes(v.$model.id) && isBuyerAdmin)
              "
            />
            <!-- PhysicalAddress fields -->
            <div v-if="v.$model.id === 11">
              <a-popover
                title="Адрес доставки должен содержать:"
                trigger="hover"
              >
                <template #content>
                  <p>Индекс, регион, населенный пункт, улицу, дом, строение</p>
                  <p>Данная информацмя передается в транспортную компанию</p>
                </template>
                <a-input
                  v-for="(item, index) in createForm.legalInfoPhysicalAddress"
                  :key="index"
                  @input="
                    e => setPhysicalAddress({ index, value: e.target.value })
                  "
                  :placeholder="v.$model.placeholder"
                  :value="item"
                  :disabled="!(isDealer || isBuyerAdmin)"
                />
              </a-popover>
              <a-button
                v-if="isManufacturerAdmin || isBuyerAdmin"
                class="btnClient"
                @click="addPhysicalAddress"
              >
                Добавить
              </a-button>
            </div>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <div
      v-if="
        this.$route.name !== 'CreateNewClient' && (isDealer || isBuyerAdmin)
      "
      class="buttonsholder"
    >
      <ButtonsHolderUpdateClient v-if="isManufacturerAdmin || isBuyerAdmin" />
    </div>
  </div>
</template>

<script>
// Юридическое лицо
import { hasRole } from "@/store/modules/auth"

let filedsClientTypeId1 = function (readonly) {
  return [
    {
      id: 3,
      name: "Код дилера",
      placeholder: "Код дилера",
      createclientname: "code",
      disabled: readonly,
      value: "",
      mask: "RSXXX",
    },
    {
      id: 2,
      name: "Время доставки до ДЦ",
      placeholder: "Введите доставки до ДЦ",
      createclientname: "deliveryPeriod",
      disabled: readonly,
      value: "",
      mask: "####",
    },

    {
      id: 4,
      name: "Наименование юридического лица",
      placeholder: "Введите полное название юр. лица",
      createclientname: "legalInfoName",
      disabled: readonly,
      value: "",
      mask: "",
    },
    {
      id: 8,
      name: "ФИО контактного лица",
      placeholder: "ФИО контактного лица",
      createclientname: "headName",
      disabled: readonly,
      value: "",
      mask: "",
    },

    {
      id: 9,
      name: "Юридический адрес",
      placeholder: "Юридический адрес",
      createclientname: "legalInfoAddress",
      disabled: readonly,
      value: "",
      mask: "",
    },
    {
      id: 7,
      name: "Должность контактного лица",
      placeholder: "Должность руководителя",
      createclientname: "headPosition",
      disabled: readonly,
      value: "",
      mask: "",
    },

    {
      id: 6,
      name: "ИНН",
      placeholder: "Введите ИНН",
      createclientname: "legalInfoInn",
      disabled: readonly,
      value: "",
      mask: "###############",
    },
    {
      id: 5,
      name: "Телефон контактного лица",
      placeholder: "Введите номер телефона",
      createclientname: "phone",
      disabled: readonly,
      value: "",
      mask: "8##########",
    },

    {
      id: 10,
      name: "Город",
      placeholder: "Город",
      createclientname: "cityId",
      disabled: readonly,
      value: "",
      mask: "",
    },
    {
      id: 12,
      name: "E-mail",
      placeholder: "Введите E-mail",
      createclientname: "email",
      disabled: readonly,
      value: "",
      mask: "",
    },

    {
      id: 11,
      name: "Фактический адрес",
      placeholder: "Фактический адрес",
      createclientname: "legalInfoPhysicalAddress",
      disabled: readonly,
      value: [],
      mask: "",
    },
    {
      id: 13,
      name: "Ограничения по массогабаритным характеристикам транспорта",
      placeholder: "Ограничения по массогабаритным характеристикам транспорта",
      createclientname: "massRestrict",
      disabled: readonly,
      value: "",
      mask: "",
    },

    // {
    //   id: 1,
    //   name: "Наименование дилера",
    //   placeholder: "Введите имя дилера",
    //   createclientname: "name",
    //   value: "",
    //   mask: "",
    // },
    // {
    //   id: 3,
    //   name: "Расчетный счет",
    //   placeholder: "Введите № расчетного счета",
    //   createclientname: "legalInfoPaymentAccount",
    //   value: "",
    //   mask: "",
    // },
    // {
    //   id: 7,
    //   name: "Наименование банка",
    //   placeholder: "Наименование банка",
    //   createclientname: "legalInfoBankName",
    //   value: "",
    //   mask: "",
    // },
    // {
    //   id: 8,
    //   name: "КПП",
    //   placeholder: "КПП",
    //   createclientname: "legalInfoKpp",
    //   value: "",
    //   mask: "",
    // },
    // {
    //   id: 9,
    //   name: "Корр. счет",
    //   placeholder: "Корр. счет",
    //   createclientname: "legalInfoCorrespondedAccount",
    //   value: "",
    //   mask: "",
    // },
    // {
    //   id: 10,
    //   name: "ОГРН",
    //   placeholder: "Введите ОГРН",
    //   createclientname: "legalInfoOgrn",
    //   value: "",
    //   mask: "",
    // },
    // {
    //   id: 12,
    //   name: "ОКПО",
    //   placeholder: "Введите ОКПО",
    //   createclientname: "legalInfoOkpo",
    //   value: "",
    //   mask: "",
    // },
    // {
    //   id: 15,
    //   name: "Документ-основание",
    //   placeholder: "Введите навзание и № документа",
    //   createclientname: "legalInfoUnderlyingDocument",
    //   value: "",
    //   mask: "",
    // },
  ]
}

// Физическое лицо
// let filedsClientTypeId2 = function () {
//   return [
//     {
//       id: 1,
//       name: "Имя",
//       placeholder: "Введите имя",
//       createclientname: "firstName",
//       value: "",
//       mask: "",
//     },
//     {
//       id: 2,
//       name: "Фамилия",
//       placeholder: "Введите фамилию",
//       createclientname: "lastName",
//       value: "",
//       mask: "",
//     },
//     {
//       id: 3,
//       name: "Отчество",
//       placeholder: "Введите отчество",
//       createclientname: "middleName",
//       value: "",
//       mask: "",
//     },
//
//     {
//       id: 4,
//       name: "Дата рождения",
//       placeholder: "Введите дату рождения в формате 1990-01-01",
//       createclientname: "birthDate",
//       value: "",
//       mask: "####-##-##",
//     },
//
//     {
//       id: 5,
//       name: "Номер телефона",
//       placeholder: "Введите номер телефона",
//       createclientname: "phone",
//       value: "",
//       mask: "8##########",
//     },
//
//     {
//       id: 6,
//       name: "Время доставки до ДЦ",
//       placeholder: "Введите доставки до ДЦ",
//       createclientname: "deliveryPeriod",
//       value: "",
//       mask: "####",
//     },
//     {
//       id: 7,
//       name: "ИНН",
//       placeholder: "Введите ИНН",
//       createclientname: "legalInfoInn",
//       value: "",
//       mask: "###############",
//     },
//     {
//       id: 8,
//       name: "ИНН",
//       placeholder: "Введите ИНН",
//       createclientname: "legalInfoInn",
//       value: "",
//       mask: "###############"
//     },
//   ]
// }

function getFieldsByClientTypeId(typeId, readonly) {
  let result = {}
  switch (typeId) {
    case "1":
      result = filedsClientTypeId1(readonly)
      break
    // case "2":
    //   result = filedsClientTypeId2()
    //   break
    default:
      result = filedsClientTypeId1(readonly)
      break
  }
  return result
}

import { mapMutations, mapGetters, mapActions } from "vuex"
import { required } from "vuelidate/lib/validators"
import ButtonsHolderUpdateClient from "@/components/clients/ButtonsHolderUpdateClient"
// import PaymentGroupNewClient from "@/components/clients/PaymentGroupNewClient"
// eslint-disable-next-line no-unused-vars
let fieldsExt
// eslint-disable-next-line no-unused-vars
const customCheckFields = function (value) {
  /*
  if (value == "ИНН") {
    let e = /^(\d{10}|\d{12})$/
    return e.test(fieldsExt.find(el => el.name == value).value)
  } else return true
  */
  return true
}
export default {
  name: "ClientData",
  components: {
    ButtonsHolderUpdateClient,
    // PaymentGroupNewClient,
  },
  computed: {
    ...mapGetters({
      createForm: "clients/createForm",
      isBuyer: "auth/isBuyer",
      isDealer: "auth/isDealer",
      isManufacturerAdmin: "auth/isManufacturerAdmin",
      clientTypeId: "clients/clientTypeId",
      cities: "clients/cities",
    }),
    isManufacturerAdmin() {
      return hasRole("MANUFACTURER_ADMIN")
    },
    isBuyerAdmin() {
      return hasRole("DEALER_ADMIN")
    },
  },
  data: () => ({
    fields: [],
    newAdress: "",
    city_id: "",
  }),
  validations: {
    fields: {
      required,
      $each: {
        value: {
          required,
        },
        name: {
          customCheckFields,
        },
      },
    },
  },
  watch: {
    createForm() {
      this.getFormFields()
    },
    clientTypeId() {
      this.fields = this.getClearFields()
    },
  },
  mounted() {
    this.fetchCities()
    if (this.$route.name === "CreateNewClient") {
      this.fields = this.getClearFields()
    } else {
      this.fields = this.getFormFields()
    }

    fieldsExt = this.fields
  },
  methods: {
    ...mapMutations({
      setField: "clients/SET_CREATE_FORM_FIELD",
      addPhysicalAddress: "clients/ADD_PHYSICAL_ADDRESS",
      setPhysicalAddress: "clients/SET_PHYSICAL_ADDRESS",
    }),
    ...mapActions({
      fetchCities: "clients/fetchCities",
    }),

    handleCityChange(value) {
      this.setField({ name: "cityId", value: value })
    },

    checkClient() {
      this.$v.fields.$touch()
    },

    getClearFields() {
      return getFieldsByClientTypeId(
        this.clientTypeId,
        !this.isManufacturerAdmin && !this.isBuyer
      )
    },
    getFormFields() {
      this.fields = getFieldsByClientTypeId(
        this.clientTypeId,
        !this.isManufacturerAdmin && !this.isBuyer
      )
      return this.fields.map(item => {
        const createFormValue = this.createForm[item.createclientname]
        if (createFormValue) {
          item.value = createFormValue
        }
        return item
      })
    },
    checkFields(e) {
      // TODO: Переделать
      if (e === "name") {
        return this.createForm.name
      } else if (e === "legalInfoPaymentAccount") {
        return this.createForm.legalInfoPaymentAccount
      } else if (e === "legalInfoName") {
        return this.createForm.legalInfoName
      } else if (e === "legalInfoBankId") {
        return this.createForm.legalInfoBankId
      } else if (e === "legalInfoInn") {
        return this.createForm.legalInfoInn
      } else if (e === "legalInfoBankName") {
        return this.createForm.legalInfoBankName
      } else if (e === "legalInfoKpp") {
        return this.createForm.legalInfoKpp
      } else if (e === "legalInfoCorrespondedAccount") {
        return this.createForm.legalInfoCorrespondedAccount
      } else if (e === "legalInfoOgrn") {
        return this.createForm.legalInfoOgrn
      } else if (e === "headPosition") {
        return this.createForm.headPosition
      } else if (e === "legalInfoOkpo") {
        return this.createForm.legalInfoOkpo
      } else if (e === "headName") {
        return this.createForm.headName
      } else if (e === "legalInfoAddress") {
        return this.createForm.legalInfoAddress
      } else if (e === "legalInfoUnderlyingDocument") {
        return this.createForm.legalInfoUnderlyingDocument
      } else if (e === "legalInfoPhysicalAddress") {
        return this.createForm.legalInfoPhysicalAddress
      }
    },
    handleAcrtionClientData() {
      this.checkClient()
      if (this.$v.$error) {
        window.scrollTo(0, "100%")
      }
    },
  },
}
</script>

<style lang="scss">
.btnClient {
  /*
  display: flex;
  align-items: center;
  justify-content: end;
  width: fit-content !important;
  margin-left: auto;
  margin-top: 5px;
   */
}
.buttonsholder {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 0 auto;
}
.clientdata {
  label {
    font-size: 13px !important;
    color: rgba(black, 0.7);
  }
}
</style>
