<template>
  <div class="flex">
    <a-button type="primary" @click="write">Сохранить</a-button>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import { message } from "ant-design-vue"
export default {
  name: "ButtonsHolderUpdateClient",
  computed: {},
  methods: {
    ...mapActions({
      updateClient: "clients/updateClient",
    }),
    async write() {
      await this.updateClient()
      message.success("Данные успешно сохранены!")
    },
  },
}
</script>

<style scoped>
.flex {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.clientinfo-button {
  margin: 20px 0 10px;
}
</style>
